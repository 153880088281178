/*Determines columns for products in Product component*/
.Images {
    margin:2rem 0 2rem 0;
    display: grid;
    grid-gap: 10px;
}

/*Determines size for each photo in the gallery*/
.Photo-Images {
    width: 100%;
    display: block;
    padding: 0.25rem 0.25rem;
    transition: .5s ease;
}


.Image-caption {
    font-family: "Arial Rounded MT", sans-serif;
}

.Product_item {
    box-sizing: border-box;
    margin: auto;
    width:100%;
    padding: 1rem;
}

/*Styling for Column that contains images*/
.Image-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-gap: 10px;
    margin: auto;
}

.Content {
    margin: 2rem 0 0 0;
}

.Info-img {
    margin:0 1rem 1rem 0;
}

.Image-title {
    color: #005826;
}

.Text-content {
    white-space: pre-line;
}

.Placeholder {
    color: #444444;
    font-family: "Arial Rounded MT", sans-serif;
}

@media screen and (min-width: 470px) and (max-width: 991px) {

    .Image-container {
        grid-template-columns: repeat(3, minmax(150px, 250px));
    }

    .Image-caption {
        font-size: 14px;
    }

    .Info-img{
        width: 70%;
    }

    .Placeholder {
        font-size: 14px;
    }

}

@media screen and (min-width: 376px) and (max-width: 470px) {

    .Image-container {
        grid-template-columns: repeat(3, minmax(120px, 220px));
    }

    .Image-caption {
        font-size: 10px;
    }

    .Placeholder {
        font-size: 14px;
    }

    .Info-img {
        width: 60%;
    }
}

@media screen and (max-width: 376px) {
        .Image-container {
            grid-template-columns: repeat(1, minmax(200px, 250px));
        }
    }

