.Home {
    width: 100%;
    padding: 8rem 0 4rem 0;
    display: inline-block;
}

.Home-container {
    width:100%;
}

.Home-carousel {
    width:100%;
}