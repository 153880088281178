/*CSS code for Foot.jsx*/

.Foot
{
    background-color: #1d1e1d;
}

.link
{
    text-decoration: none;
    color: #f8f8f8;
}

.link:hover
{
    text-decoration: none;
    color: #aaaaaa;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.list
{
    padding: 3.5% 0 2% 5%;
    margin: 0 auto;
    text-align:center;
}

.terms {
    font-size: 20px;
}

.list-unstyled
{
    padding: 0 2% 0 2%;
    text-align:center;
}

.Foot .footer-copyright
{
    margin: 0 auto;
    padding: 0 2% 0 0;
    font-size: 20px;
    color: #aaaaaa;
}

.developer-copyright
{
    margin: 0 auto;
    padding: 2% 0 2% 5%;
    color: #aaaaaa;
    text-align: center;
    font-size:14px;
}

.horizontal
{
    display: inline-block;
    border-left: #aaaaaa 2px solid;
    padding-left: 2%;
}
.first
{
    border-left: none;
    padding-left: 0;
}

/*responsive styling*/

@media screen and (min-width: 991px) and (max-width: 1199px) {
    .list-unstyled {
        font-size:15px;
    }
    .Foot .footer-copyright
    {
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .list {
        padding: 5% 0 5% 15%;
    }

    .list-unstyled {
        font-size:15px;
    }
    .Foot .footer-copyright
    {
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {

    .list {
        padding: 5% 7% 5% 10%;
    }

    .list-unstyled {
        font-size:12px;
    }
    .Foot .footer-copyright
    {
        font-size: 12px;
    }

}