/*Styles Wrapper for navigation items*/
.Navbar {
    transition: top 0.2s ease-in-out;
    width: 100%;
    display: flex;
    height: 115px;
    margin-bottom: 2rem;
    background: #f8f9fa;
    top: 0;
    left: 0;
    position: fixed;
}

.Navbar-links{
    color: #282c34;
}

.mr-auto {
    background: #f8f9fa;
}

.Navbar-nav-items {
    padding: 0 1rem;
    height: 5.9em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    background: #f8f9fa;
}

.navbar-expand-lg .fixed-top {
    color: #f8f9fa;
}


.Navbar-nav-items:hover,
.Navbar-nav-items:active{
    background: #005826;
    transition: 0.3s;
}

.Navbar-nav-items:hover .Navbar-links,
.Navbar-nav-items:active{
    color: #F5F5F5;
    transition: 0.3s;
}

.Logo-container {
    margin: 2% 2% 2% 2%;
}

.Logo {
    width:6rem;
}

/*creates space between logo and navigation*/
.spacer {
    flex: 100;
}

@media screen and (max-width:991px) {
    .Navbar-nav-items {
        width:100%;
    }
    .navbar-expand-lg {
        padding: 0 0.25rem 0 0.25rem;
        background: #f8f9fa;
    }
}