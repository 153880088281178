/*Styles Wrapper for navigation items*/
.Toolbar-nav {
    height:100%;
    display: inline-block;
}

.Toolbar-dropdown {
    float:left;
    /*overflow: hidden;*/
    position: relative;
    display: inline-block;
}

.Toolbar-dropdown .Toolbar-dropdown-btn{
    font-size: 1.5rem;
    border: none;
    outline: none;
    color: #B00000;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
    padding-top:0.6rem;
    cursor: pointer;
}

.Toolbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: #005826;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.Toolbar-dropdown-content .Toolbar-links {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.Toolbar-dropdown-content a:hover {
    background-color: black;
    transition: 0.3s;
}

/* Show the dropdown menu on hover */
.Toolbar-dropdown:hover .Toolbar-dropdown-content {
    display: block;
    transition: 3s;
}

/*Style navigation list for toolbar*/
.Toolbar-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

/*Styles each item in the navigation section*/
.Toolbar-nav-items {
    padding: 0 1rem;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Styles link portion of navigation list*/
.Toolbar-nav-items a {
    color: #B00000;
    text-decoration:none;
    font-size: 1.5rem;
}

.Toolbar-list-rvp {
    /*width: 315px;*/
}

/*Creates animation on hovering and clicking of navigation list*/
.Toolbar-nav-items:hover,
.Toolbar-nav-items:active,
.Toolbar-dropdown-btn:hover,
.Toolbar-dropdown-btn:active{
    background: #005826;
    transition: 0.3s;
}

/*Creates animation on hovering and clicking of navigation list link*/
.Toolbar-nav-items:hover a,
.Toolbar-nav-items:active,
.Toolbar-dropdown-btn:hover,
.Toolbar-dropdown-btn:active{
    color: #F5F5F5;
    transition: 0.3s;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {

    .Toolbar-nav-items a {
        font-size: 1.1rem;
    }
    .Toolbar-dropdown .Toolbar-dropdown-btn{
        font-size: 1.1rem;
        padding-top: 1rem;
    }

    .Toolbar-dropdown .Toolbar-list-org {
        font-size: 1.1rem;
    }

    .Toolbar-dropdown .Toolbar-list-rvp {
        font-size: 1.1rem;
    }

    .Toolbar-nav-items {
        padding: 0 0.5rem;
    }
}

/*Responsive media*/
@media screen and (min-width: 763px) and (max-width: 991px) {

    .Toolbar-nav-items a {
        font-size: 0.95rem;
    }

    .Toolbar-dropdown .Toolbar-dropdown-btn{
        font-size: 0.95rem;
        padding-top: 1rem;
    }

    .Toolbar-dropdown .Toolbar-list-org {
        font-size: 0.95rem;
    }

    .Toolbar-dropdown .Toolbar-list-rvp {
        font-size: 0.95rem;
    }

    .Toolbar-nav-items {
        padding: 0 0.5rem;
    }
}

@media screen and (min-width: 378px) and (max-width: 763px) {

    .Toolbar-nav-items a {
        font-size: 0.68rem;
    }

    .Toolbar-dropdown .Toolbar-dropdown-btn{
        font-size: 0.68rem;
        padding-top: 1.3rem;
    }

    .Toolbar-dropdown .Toolbar-list-org {
        font-size: 0.68rem;
    }

    .Toolbar-dropdown .Toolbar-list-rvp {
        font-size: 0.68rem;
    }

}

@media screen and (max-width: 378px) {

    .Toolbar-nav-items a {
        font-size: 0.63rem;
    }

    .Toolbar-dropdown .Toolbar-dropdown-btn{
        font-size: 0.63rem;
        padding-top: 1rem;
    }

    .Toolbar-dropdown .Toolbar-list-org {
        font-size: 0.63rem;
    }

    .Toolbar-dropdown .Toolbar-list-rvp {
        font-size: 0.63rem;
    }

    .Placeholder{
        font-size: 14px;
    }

}